<template>
    <form
        ref="formRef"
        method="post"
        @submit.prevent="onSubmit"
        @keydown.enter.prevent="onSubmit"
    >
        <div
            v-if="formErrors.form || fieldErrorMessages.length"
            id="form-errors"
            :class="formErrorClass"
        >
            <Alert
                v-if="formErrors.form"
                type="error"
                :class="compact ? 'mb-5' : 'mb-8'"
                slot-class="wysiwyg-content wysiwyg-compact"
                data-cy="form-errors-alert"
            >
                <p :class="{ 'text-sm': compact }">{{ formErrors.form }}</p>
            </Alert>

            <Alert
                v-if="fieldErrorMessages.length"
                type="error"
                :class="compact ? 'mb-5' : 'mb-8'"
                slot-class="wysiwyg-content wysiwyg-compact"
                data-cy="field-errors-alert"
            >
                <p :class="{ 'text-sm': compact }">{{ errorAlertTitle }}</p>

                <ul :class="{ 'text-sm': compact }">
                    <li v-for="message in fieldErrorMessages" :key="message">
                        {{ message }}
                    </li>
                </ul>
            </Alert>
        </div>

        <div
            v-if="showRequiredFieldsMessage"
            :class="compact ? 'mb-5 text-sm' : 'mb-6'"
        >
            <span class="input-required-indicator">*</span>
            Required fields
        </div>

        <slot />
    </form>
</template>

<script setup lang="ts">
import { FormErrors } from '~/types/miscellaneous';

const emit = defineEmits(['submit']);

const props = withDefaults(
    defineProps<{
        compact?: boolean;
        errorAlertTitle?: string;
        formErrors?: FormErrors;
        showRequiredFieldsMessage?: boolean;
        formErrorClass?: string;
    }>(),
    {
        compact: false,
        errorAlertTitle: 'Sorry, but there was a problem with your submission:',
        formErrors: () => ({}),
        showRequiredFieldsMessage: false,
        formErrorClass: '',
    },
);

const formRef = ref<HTMLFormElement | null>(null);

const fieldErrorMessages = computed(() => {
    const messages: string[] = [];

    Object.keys(props.formErrors).forEach((key) => {
        if (key === 'form') {
            return;
        }

        const error = props.formErrors[key];

        if (Array.isArray(error)) {
            messages.push(...error);
        } else {
            messages.push(error);
        }
    });

    return messages;
});

const validate = () => {
    if (!formRef.value) {
        return null;
    }

    if (!formRef.value.checkValidity()) {
        formRef.value.reportValidity();
        return false;
    }

    return true;
};

const onSubmit = () => {
    if (!validate()) {
        return;
    }

    emit('submit', formRef.value);
};

const getFormData = () => {
    if (!formRef.value) {
        return null;
    }

    return new FormData(formRef.value);
};

defineExpose({
    validate,
    onSubmit,
    getFormData,
});
</script>
