<template>
    <div
        class="relative rounded-lg border-2 px-5 py-4 font-semibold"
        :class="[`bg-${type}-background`, `border-${type}`]"
        :data-cy="'alert-' + type"
    >
        <div class="md:flex md:gap-4">
            <Icon
                :name="iconName"
                class="flex-shrink-0 text-[26px]"
                :class="`text-${type}`"
            />

            <p class="mt-4 flex-1 md:mt-0 md:pr-5" :class="slotClass">
                <slot />
            </p>
        </div>

        <button
            v-if="dismissable"
            class="absolute right-5 top-5 text-grind transition hover:text-brew"
            @click.prevent="$emit('dismiss')"
        >
            <Icon name="close" class="text-[16px]" />
        </button>
    </div>
</template>

<script setup lang="ts">
defineEmits(['dismiss']);

const props = withDefaults(
    defineProps<{
        type: 'error' | 'info' | 'success' | 'warning';
        dismissable?: boolean;
        slotClass?: string;
    }>(),
    {
        dismissable: false,
        slotClass: '',
    },
);

const iconName = computed(() => {
    if ('success' === props.type) {
        return 'circle-check-solid';
    }

    if ('info' === props.type) {
        return 'circle-info-solid';
    }

    return 'circle-exclamation-solid';
});
</script>
