<template>
    <div>
        <div class="relative">
            <slot />

            <InputLabel v-if="label" :for="inputId" :class="labelClass">
                {{ labelWithoutRequiredIndicator }}
                <span
                    v-if="showRequiredIndicator"
                    class="input-required-indicator"
                >
                    *
                </span>
            </InputLabel>

            <InputLabel v-if="$slots.label" :for="inputId" :class="labelClass">
                <slot name="label" />
            </InputLabel>

            <slot name="after-label" />
        </div>

        <InputErrors :errors="errors" :name="name" />

        <InputHelpText
            v-if="helpText"
            :data-cy="`input-${name}-help-text`"
            :class="helpTextClass"
        >
            {{ helpText }}
        </InputHelpText>

        <InputHelpText
            v-if="$slots.helpText"
            :data-cy="`input-${name}-help-text`"
        >
            <slot name="helpText" />
        </InputHelpText>
    </div>
</template>

<script setup lang="ts">
import InputErrors from './InputErrors.vue';
import InputHelpText from './InputHelpText.vue';
import InputLabel from './InputLabel.vue';

const props = withDefaults(
    defineProps<{
        name: string;
        inputId?: string;
        label?: string;
        labelClass?: string;
        helpText?: string;
        helpTextClass?: string;
        errors?: string[];
    }>(),
    {
        inputId: undefined,
        label: undefined,
        labelClass: '',
        helpText: undefined,
        helpTextClass: 'px-4',
        errors: () => [],
    },
);

const requiredIndicatorRegex = /^(.+)\s+\*$/;

const labelWithoutRequiredIndicator = computed(() => {
    if (!props.label) {
        return null;
    }

    const match = props.label.match(requiredIndicatorRegex);
    return match ? match[1] : props.label;
});

const showRequiredIndicator = computed(() => {
    if (!props.label) {
        return false;
    }

    return !!props.label.match(requiredIndicatorRegex);
});
</script>
