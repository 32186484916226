<template>
    <InputContainer
        :name="name"
        :input-id="id"
        :label="label"
        :help-text="helpText"
        :errors="errors"
        :class="containerClass"
    >
        <input
            :id="id"
            ref="inputRef"
            v-model="value"
            :aria-invalid="isErrored ? 'true' : 'false'"
            :aria-errormessage="isErrored ? 'input-error-' + name : ''"
            :type="type"
            :name="name"
            :disabled="submitting"
            v-bind="$attrs"
            :class="[{ 'input-is-errored': isErrored }, inputClass]"
            :placeholder="placeholder"
            :data-cy="'input-' + name"
        />
    </InputContainer>
</template>

<script setup lang="ts">
import InputContainer from './components/InputContainer.vue';
import {
    defaultEmits,
    DefaultProps,
    defaultPropsDefaults,
} from '~/composables/useInput';

defineOptions({
    inheritAttrs: false,
});

const inputRef = ref<HTMLInputElement | null>(null);

const emit = defineEmits([...defaultEmits]);

const props = withDefaults(defineProps<DefaultProps>(), defaultPropsDefaults);

const { id, value, errors, isErrored } = useInput(props, emit);

const focus = () => {
    inputRef.value?.focus();
};

defineExpose({ focus });
</script>
